import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vaibhav from '../images/team/vaibhav.png';
import Suhani from '../images/team/suhani.png';
import Anup from '../images/team/Anup.png';
import Pushkar from '../images/team/pushkar.png';
import Ravi from '../images/team/ravi.png';
import Venkat from '../images/team/venkat.png'


 
const TeamCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const teamMembers = [
    {
      name: "Ravi Sankar",
      role: "CEO",
      image: Ravi,
      linkedIn: "https://www.linkedin.com/in/ravi-m-a96ba72ba/"
    },
    {
      name: "Venkata Krishna",
      role: "Sr. Machine Learning Engineer",
      image: Venkat,
      linkedIn: "https://www.linkedin.com/in/venkatakrishna-akula/"
    },
    {
      name: "Anup Prakash",
      role: "Sr. Backend Engineer",
      image: Anup,
      linkedIn: "https://www.linkedin.com/in/anup-prakash/"
    },
    {
      name: "Pushkar Sharma",
      role: "Sr. Full-Stack Engineer",
      image: Pushkar,
      linkedIn: "https://www.linkedin.com/in/pushkar-sharma-7b1340192/"
    },
    {
      name: "Suhani Jain",
      role: "Full-Stack Engineer",
      image: Suhani,
      linkedIn: "https://www.linkedin.com/in/suhani-jain-422845207/"
    },
    {
      name: "Vaibhav Maheshwari",
      role: "Backend Engineer",
      image: Vaibhav,
      linkedIn: "https://www.linkedin.com/in/maheshwari05"
    }
  ];

  return (
    <div className="w-full overflow-hidden bg-gray-100 py-12" id="team">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl text-center text-blue-900 uppercase font-bold mb-8">Our Team</h2>
        <div className="w-24 border-b-4 border-blue-900 mx-auto mb-8"></div>
        
        <Slider {...settings}>
          {teamMembers.map((member, index) => (
            <div key={index} className="px-2">
              <div className="bg-white rounded-lg shadow-xl p-6 m-2 transition-all duration-300 ease-in-out hover:shadow-2xl">
                <a href={member.linkedIn} target="_blank" rel="noopener noreferrer" className="block">
                  <div className="w-32 h-32 mx-auto mb-4 overflow-hidden rounded-full">
                    <img 
                      src={member.image} 
                      alt={member.name} 
                      className="w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                    />
                  </div>
                  <h3 className="font-semibold text-lg text-center">{member.name}</h3>
                  <p className="text-gray-600 text-center">{member.role}</p>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TeamCarousel;