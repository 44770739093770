import React from 'react';
import acrodocz from '../images/clients/acrodocz.png';
import dla from '../images/clients/DeepLA.png';
import theranow from '../images/clients/Theranow.png';
import touchcast from '../images/clients/touchcast.png';
import zigniteLabs from '../images/clients/zignite_labs.png';

const clientImageContainer = {
    height: '200px',
    width: '200px',
    margin: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.3s ease-in-out'
};

const clientImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    mixBlendMode: 'multiply',
};

const Clients = () => {
    const clients = [
        { image: acrodocz, url: 'https://www.acrodocz.com/' },
        { image: dla, url: 'https://deeplearninganalytics.org/' },
        { image: theranow, url: 'https://www.theranow.com/' },
        { image: touchcast, url: 'https://touchcast.com/' },
        { image: zigniteLabs, url: 'https://www.zignite.io/' },
    ];

    return (
        <div className="mt-8 bg-gray-100" id="clients">
            <section data-aos="fade-up">
                <div className="my-2 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Clients</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                </div>

                <div className="p-0" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                        {clients.map((client, index) => (
                            <div key={index} style={clientImageContainer} className="overflow-hidden transition-all ease-in-out opacity-50 hover:opacity-100">
                                <a href={client.url} target='_blank' rel="noopener noreferrer">
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img src={client.image} alt="client" style={clientImageStyle} />
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Clients;